<template>
  <div>
    here
    <nav class="fixed top-0 z-50 w-full h-14">
      <div class="bg-white border-b border-gray-200 shadow">
        <div class="flex flex-wrap items-center px-4 py-2">
          <div class="mr-4">
            <sa-icon-button
              with-bg
              iconClass="text-xl bx bx-menu"
              @click.stop="handleMenuClick"
            />
          </div>
          <div
            @click="$router.push({ name: 'home.index' })"
            class="cursor-pointer flex flex-row flex-wrap items-center space-x-1 no-underline"
          >
            <img
              src="@/assets/img/mini-responsive-samii.png"
              v-if="!logo"
              alt=""
              style="max-height: 40px; object-fit: contain"
              class="m-1"
            />
            <img
              :src="logo"
              v-if="logo"
              alt=""
              class="m-1"
              style="max-height: 45px; object-fit: contain"
            />
            <span class="font-bold text-gray-600 text-md font-headline">{{
              $store.state.business.name || "Fixme up"
            }}</span>
          </div>
          <div class="fixed right-4">
            <el-button
              class="text-gray-700 hover:text-purple-500"
              @click.stop="$router.push({ name: 'home.index' })"
            >
              Atras
            </el-button>
          </div>
        </div>
      </div>
    </nav>
    <div class="min-h-screen bg-gray-50">
      <aside
        class="fixed h-screen border-r border-gray-200"
        :class="{ 'w-16': collapsed, 'w-64': !collapsed, disabled: disabled }"
      >
        <nav>
          <div class="flex flex-col w-full text-gray-500">
            <div>
              <ul
                class="flex flex-col flex-grow space-y-1"
                :class="{ disabled: disabled }"
              >
                <li
                  id="sub-title-1"
                  v-if="
                    !collapsed &&
                    ($store.state.account.accountType === 1 ||
                      $store.state.account.canViewAdministration)
                  "
                >
                  <span
                    class="flex px-4 my-2 mt-4 text-sm font-bold text-gray-400 uppercase"
                  >
                    Administrar mi negocio
                  </span>
                </li>
                <li
                  class="flex-grow border-purple-500 cursor-pointer hover:bg-gray-100 hover:text-purple-500"
                  :class="{
                    'border-l-4 bg-gray-100 border-purple-500 text-purple-500':
                      isCurrentSlot(item.name),
                  }"
                  v-for="item in routes"
                  :key="item.name"
                  :id="item.name"
                >
                  <span
                    class="flex items-center p-2 space-x-2"
                    :class="collapsed ? 'justify-center' : ''"
                  >
                    <i
                      class="text-xl"
                      :class="item.iconClass"
                      @click.stop="replaceSlot(item.name)"
                    >
                    </i>
                    <span
                      v-if="!collapsed"
                      class="font-semibold"
                      @click.stop="replaceSlot(item.name)"
                      >{{ item.label }}</span
                    >
                    <pro-link
                      v-if="!collapsed && !isProMembership && item.validatePro"
                    ></pro-link>
                  </span>
                </li>
                <li id="sub-title-2" v-if="!collapsed">
                  <span
                    class="flex px-4 my-2 mt-4 text-sm font-bold text-gray-400 uppercase"
                    >Administrar mi cuenta</span
                  >
                </li>
                <li
                  class="flex-grow border-purple-500 cursor-pointer hover:bg-gray-100 hover:text-purple-500"
                  :class="
                    isCurrentSlot(item.name)
                      ? 'border-l-4 bg-gray-100 border-purple-500 text-purple-500'
                      : ''
                  "
                  v-for="item in account"
                  :key="item.name"
                  @click.stop="replaceSlot(item.name)"
                >
                  <span
                    class="flex items-center p-2 space-x-2"
                    :class="collapsed ? 'justify-center' : ''"
                  >
                    <i class="text-xl" :class="item.iconClass"> </i>
                    <span v-if="!collapsed" class="font-semibold">{{
                      item.label
                    }}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </aside>
      <main class="mt-1" :class="collapsed ? 'ml-16' : 'ml-64'">
        <div class="text-gray-500 text-lg absolute pt-5 pb-0 pl-2">
          {{ office }}
        </div>
        <div class="px-8">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
<style scoped>
.disabled:not(#my-membership) {
  cursor: not-allowed !important;
}

.disabled > li:not(#my-membership):not(#sub-title-1):not(#sub-title-2) {
  text-decoration: line-through;
}

.disabled:hover:not(#my-membership) {
  color: inherit;
  background-color: inherit;
}
</style>
<script>
import BusinessGetters from "@/store/modules/business/getters";
import responsiveSize from "@/mixins/responsiveSize";

export const Routes = {
  business: {
    label: "Mi negocio",
    name: "business",
    iconClass: "bx bx-info-circle",
  },
  membership: {
    label: "Mi membresía",
    name: "membership",
    iconClass: "bx bx-package",
  },
  usersAndPermissions: {
    label: "Usuarios y permisos",
    name: "users",
    iconClass: "bx bxs-user-account",
  },
  branchOffices: {
    label: "Sucursales",
    name: "offices",
    iconClass: "bx bx-store",
    validatePro: true,
  },
  notifications: {
    label: "Notificaciones",
    name: "notifications",
    iconClass: "bx bxs-bell",
  },
  policiesAndTemplates: {
    label: "Políticas y plantillas",
    name: "orderPreferences",
    iconClass: "bx bx-receipt",
  },
  data: { label: "Mis datos", name: "account", iconClass: "bx bx-data" },
  security: {
    label: "Seguridad",
    name: "security",
    iconClass: "bx bx-shield-quarter",
  },
  importExport: {
    label: "Importar/Exportar",
    name: "importExport",
    iconClass: "bx bx-cloud-upload",
  },
  catalogs: {
    label: "Catálogos",
    name: "catalogs",
    iconClass: "bx bx-book-open",
  },
};

export default {
  name: "BusinessAdmin",
  mixins: [responsiveSize],
  components: {
    ProLink: () => import("@/components/atoms/SaProLink.vue"),
  },
  data() {
    let routes = [];
    const isOwner = this.$store.state.account.accountType === 1;
    const { canViewAdministration, canViewBranchOffices } =
      this.$store.state.account;

    if (isOwner || canViewAdministration) {
      routes = [
        Routes.business,
        Routes.membership,
        Routes.usersAndPermissions,
        Routes.branchOffices,
        Routes.notifications,
        Routes.policiesAndTemplates,
        Routes.importExport,
        Routes.catalogs,
      ];
    } else if (canViewBranchOffices) {
      routes.push(Routes.branchOffices);
    }

    return {
      isProMembership:
        this.$store.state.account.membershipType === 2 ||
        this.$store.state.account.membershipType === 3,
      collapsed: false,
      slot:
        this.$route.params.membership === "1" ? "membership" : this.$route.name,
      routes,
      account: [Routes.data, Routes.security],
      scrollToElementInSlot: null,
      importExportType: null,
      brancOffice: null,
      action: null,
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    this.action = this.$route.params.action || null;
    if (this.$route.params.scrollToElementInSlot) {
      this.scrollToElementInSlot = this.$route.params.scrollToElementInSlot;
    }
    if (this.$route.params.importExportType) {
      this.importExportType = this.$route.params.importExportType;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const membership = urlParams.get("membership");
    if (membership === "1") {
      this.slot = "membership";
    }
  },
  computed: {
    logo() {
      return BusinessGetters.logo();
    },
    disabled() {
      return this.$store.state.account.membershipLimitReached;
    },
    office() {
      return this.$store.state.business.id
        ? `Sucursal ${this.$store.state.business.name}`
        : `GENERAL`;
    },
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.collapsed = percentageWidth === "90%";
    },
    handleMenuClick() {
      this.collapsed = !this.collapsed;
    },
    replaceSlot(itemName) {
      if (this.disabled) return;
      this.slot = itemName;
      this.$router.push({ name: `administration.${itemName}` });
    },
    isCurrentSlot(routePath) {
      return this.slot.includes(routePath);
    },
  },
};
</script>
