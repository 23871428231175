var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v(" here "),_c('nav',{staticClass:"fixed top-0 z-50 w-full h-14"},[_c('div',{staticClass:"bg-white border-b border-gray-200 shadow"},[_c('div',{staticClass:"flex flex-wrap items-center px-4 py-2"},[_c('div',{staticClass:"mr-4"},[_c('sa-icon-button',{attrs:{"with-bg":"","iconClass":"text-xl bx bx-menu"},on:{"click":function($event){$event.stopPropagation();return _vm.handleMenuClick.apply(null, arguments)}}})],1),_c('div',{staticClass:"cursor-pointer flex flex-row flex-wrap items-center space-x-1 no-underline",on:{"click":function($event){return _vm.$router.push({ name: 'home.index' })}}},[(!_vm.logo)?_c('img',{staticClass:"m-1",staticStyle:{"max-height":"40px","object-fit":"contain"},attrs:{"src":require("@/assets/img/mini-responsive-samii.png"),"alt":""}}):_vm._e(),(_vm.logo)?_c('img',{staticClass:"m-1",staticStyle:{"max-height":"45px","object-fit":"contain"},attrs:{"src":_vm.logo,"alt":""}}):_vm._e(),_c('span',{staticClass:"font-bold text-gray-600 text-md font-headline"},[_vm._v(_vm._s(_vm.$store.state.business.name || "Fixme up"))])]),_c('div',{staticClass:"fixed right-4"},[_c('el-button',{staticClass:"text-gray-700 hover:text-purple-500",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({ name: 'home.index' })}}},[_vm._v(" Atras ")])],1)])])]),_c('div',{staticClass:"min-h-screen bg-gray-50"},[_c('aside',{staticClass:"fixed h-screen border-r border-gray-200",class:{ 'w-16': _vm.collapsed, 'w-64': !_vm.collapsed, disabled: _vm.disabled }},[_c('nav',[_c('div',{staticClass:"flex flex-col w-full text-gray-500"},[_c('div',[_c('ul',{staticClass:"flex flex-col flex-grow space-y-1",class:{ disabled: _vm.disabled }},[(
                  !_vm.collapsed &&
                  (_vm.$store.state.account.accountType === 1 ||
                    _vm.$store.state.account.canViewAdministration)
                )?_c('li',{attrs:{"id":"sub-title-1"}},[_c('span',{staticClass:"flex px-4 my-2 mt-4 text-sm font-bold text-gray-400 uppercase"},[_vm._v(" Administrar mi negocio ")])]):_vm._e(),_vm._l((_vm.routes),function(item){return _c('li',{key:item.name,staticClass:"flex-grow border-purple-500 cursor-pointer hover:bg-gray-100 hover:text-purple-500",class:{
                  'border-l-4 bg-gray-100 border-purple-500 text-purple-500':
                    _vm.isCurrentSlot(item.name),
                },attrs:{"id":item.name}},[_c('span',{staticClass:"flex items-center p-2 space-x-2",class:_vm.collapsed ? 'justify-center' : ''},[_c('i',{staticClass:"text-xl",class:item.iconClass,on:{"click":function($event){$event.stopPropagation();return _vm.replaceSlot(item.name)}}}),(!_vm.collapsed)?_c('span',{staticClass:"font-semibold",on:{"click":function($event){$event.stopPropagation();return _vm.replaceSlot(item.name)}}},[_vm._v(_vm._s(item.label))]):_vm._e(),(!_vm.collapsed && !_vm.isProMembership && item.validatePro)?_c('pro-link'):_vm._e()],1)])}),(!_vm.collapsed)?_c('li',{attrs:{"id":"sub-title-2"}},[_c('span',{staticClass:"flex px-4 my-2 mt-4 text-sm font-bold text-gray-400 uppercase"},[_vm._v("Administrar mi cuenta")])]):_vm._e(),_vm._l((_vm.account),function(item){return _c('li',{key:item.name,staticClass:"flex-grow border-purple-500 cursor-pointer hover:bg-gray-100 hover:text-purple-500",class:_vm.isCurrentSlot(item.name)
                    ? 'border-l-4 bg-gray-100 border-purple-500 text-purple-500'
                    : '',on:{"click":function($event){$event.stopPropagation();return _vm.replaceSlot(item.name)}}},[_c('span',{staticClass:"flex items-center p-2 space-x-2",class:_vm.collapsed ? 'justify-center' : ''},[_c('i',{staticClass:"text-xl",class:item.iconClass}),(!_vm.collapsed)?_c('span',{staticClass:"font-semibold"},[_vm._v(_vm._s(item.label))]):_vm._e()])])})],2)])])])]),_c('main',{staticClass:"mt-1",class:_vm.collapsed ? 'ml-16' : 'ml-64'},[_c('div',{staticClass:"text-gray-500 text-lg absolute pt-5 pb-0 pl-2"},[_vm._v(" "+_vm._s(_vm.office)+" ")]),_c('div',{staticClass:"px-8"},[_vm._t("default")],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }